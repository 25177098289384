import { lineString } from '@turf/helpers';
import { length, point } from '@turf/turf';
import { LngLatBounds } from 'mapbox-gl';
import { fetchApi } from './api';
import {
  FRC,
  SegmentFeature,
  SegmentResponseItem,
  RegionDto,
} from 'model/RegionDto';

export const SegmentsApi = {
  getSegments: async (
    bounds: LngLatBounds,
    mapVersion: string,
    mapType: string,
    frcs?: FRC[],
  ): Promise<SegmentFeature[]> => {
    const boundingBox = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ].join(',');
    const uniqueFrcs: FRC[] = [...new Set(frcs ?? [])];
    const frcQuery =
      uniqueFrcs && uniqueFrcs.length > 0 && uniqueFrcs.length < 8
        ? `&frcs=${uniqueFrcs.join(',')}`
        : '';

    const response = await fetchApi<SegmentResponseItem[]>(
      `/rest/maps/${mapType}/${mapVersion}/edges?boundingBox=${boundingBox}${frcQuery}`,
    );

    const segments = response.map(
      ([id, geo, inbound, outbound, mergedFrom, frc]) => {
        const segmentLength = length(lineString(geo), { units: 'meters' });

        return lineString(geo, {
          id,
          inbound,
          outbound,
          length: segmentLength,
          mergedFrom,
          frc,
        });
      },
    );

    return segments;
  },
  getSegmentsIntersectRegion: async (
    region: RegionDto,
    mapVersion: string,
    mapType: string,
    frcs: number[],
  ) => {
    const response = await fetchApi(
      `/rest/maps/${mapType}/${mapVersion}/roads/intersect?frcs=${frcs.join()}`,
      {
        method: 'POST',
        body: JSON.stringify(region),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      'json',
    );

    const intersectionsPoints = intersectionsToPoint(response as any[]);

    return intersectionsPoints;
  },
};

const intersectionsToPoint = (intersections: any[]) => {
  return intersections.map((it) => {
    const [frc, type, geo, bearing] = it;

    return point(geo, {
      frc,
      type,
      bearing,
    });
  });
};
