import area from '@turf/area';
import AnalysisApi from 'api/AnalysisApi';
import LimitsApi, { LimitsWithUsage } from 'api/LimitsApi';
import { UserRole } from 'legoland-sdk/dist/experimental';
import {
  dataSourceToDataType,
  dataSourceToVehicleTypes,
  isRegionAnalysis,
} from 'logic/analysis/analysisUtils';
import { isDateRangeValid, twoYearsDateRange } from 'logic/time/timeValidation';
import { Analysis, AnalysisType } from 'model/AnalysisDto';
import { DataSource, MapMatchOption } from 'model/AnalysisParameters';
import { DayOfWeek } from 'model/DayOfWeek';
import { RegionDto } from 'model/RegionDto';
import { DateRangeDto, TimeRangeCondition } from 'model/TimeDto';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DEFAULT_SL_RADIUS_IN_KILOMETERS,
  MenuState,
  useMenu,
} from 'reducers/menuReducer';
import simplepolygon from 'simplepolygon';
import { Button, ButtonVariant } from 'tombac';
import { CloneIcon } from 'tombac-icons';
import { useIsAdmin } from 'user';
import { NewReportModal } from '../AnalysisNew/NewReportModal';
import { hasSelfIntersections } from '../AnalysisNew/RegionSelection/areaValidation';
import {
  validateRegion,
  ValidationStatus,
} from '../AnalysisNew/RegionSelection/validation';
import AnalysisCloneModal from './AnalysisCloneModal';
import { SimpleTooltip } from './SimpleTooltip';
import { isSelectedLinkRegion } from 'components/SelectedLinkPage/utils';
import { metersToKm } from 'logic/unit';

interface Props {
  analysisId: number;
  analysisType: AnalysisType;
  userRole: UserRole;
  size: 'big' | 'small';
  isTransparent?: boolean;
  variant?: ButtonVariant;
}

interface AnalysisCloneValidationResult {
  validatedRegions: RegionDto[];
  invalidDateRanges: number[];
}

const nonPropagated = (f: (a?: any) => void) => (
  e?: React.SyntheticEvent | any,
) => {
  e.preventDefault();
  e.stopPropagation();
  f();
};

function AnalysisCloneButton(props: Props) {
  const { analysisId, userRole, size, analysisType } = props;
  const history = useHistory();
  const push = history.push;
  const isAdmin = useIsAdmin();
  const [, setMenu] = useMenu();

  const [cloneLoading, setCloneLoading] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showNewReportModal, setShowNewReportModal] = useState(false);
  const [unChangedAnalysis, setUnchangedAnalysis] = useState<Analysis>();
  const [
    analysisWithoutInvalidData,
    setAnalysisWithoutInvalidData,
  ] = useState<Analysis>();
  const [invalidDateRanges, setInvalidDateRanges] = useState([]);
  const [invalidRegions, setInvalidRegions] = useState([]);
  const [dateRangeLimit, setDateRangeLimit] = useState(
    twoYearsDateRange(isAdmin),
  );
  const [minDaysInDateRange, setMinDaysInDateRange] = useState(28);
  const limits = LimitsApi.use();

  const setAnalysisToMenu = (analysis: Analysis) => {
    const menu: Partial<MenuState> = {
      type: analysis.info.type ?? AnalysisType.FlowMatrix,
      passMatrix: analysis.info?.passMatrix ?? false,
      name: analysis.info.name ?? '',
      organizations: new Set(analysis.info?.organizations),

      tripStats: analysis.info.tripStats ?? true,
      zoneId: analysis.info?.timeDefinition?.zoneId ?? 'Etc/UTC',
      dateRanges: analysis.info?.timeDefinition?.dateRanges ?? [],
      timeRanges: analysis.info?.timeDefinition?.timeRanges ?? [],
      daysOfWeek:
        analysis.info?.timeDefinition?.daysOfWeek ?? Object.values(DayOfWeek),
      timeRangeCondition:
        analysis.info?.timeDefinition?.timeRangeCondition ??
        TimeRangeCondition.WHOLE,
      startedFromDraftId: undefined,
      isEdited: false,
      dataType: dataSourceToDataType(
        analysis.info?.dataSources ?? DataSource.ALL,
      ),
      vehicleTypes: dataSourceToVehicleTypes(
        analysis.info?.dataSources ?? DataSource.ALL,
      ),
      mapVersion: analysis.info?.map?.version,
      mapType: analysis.info?.map?.type,
      bufferRadiusInKilometers:
        metersToKm(analysis.info.bufferRadiusInMeters) ??
        DEFAULT_SL_RADIUS_IN_KILOMETERS,
      mapMatchOption: analysis.info.mapMatchOption ?? MapMatchOption.Auto,
    };
    if (
      isRegionAnalysis(analysis.info.type) ||
      isSelectedLinkRegion(analysis)
    ) {
      menu.regions = analysis.regions;
    } else {
      menu.links = analysis.regions as any;
    }
    setMenu(menu);
  };

  const clone = async (analysisId: number, userRole: UserRole) => {
    setCloneLoading(true);

    const fetchedAnalysis = await AnalysisApi.get(analysisId, analysisType);
    fetchedAnalysis.regions = fetchedAnalysis.regions.map((region) => ({
      ...region,
      properties: {
        ...region.properties,
        validationResult: validateRegion(
          region,
          analysisType,
          limits.limits.allowedArea,
        ),
      },
    }));
    const regionsWithSelfIntersections = fetchedAnalysis.regions
      .map((region, index) => ({ region, originalIndex: index }))
      .filter((it) => hasSelfIntersections(it.region))
      .map((it) => it.originalIndex);

    const regionsWithoutSelfIntersections: RegionDto[] = regionsWithSelfIntersections.flatMap(
      (index: number) => {
        const region = fetchedAnalysis.regions[index];
        const withoutSelfIntersections = simplepolygon(region).features;
        const orderedByAreaDesc = withoutSelfIntersections.sort(
          (a: any, b: any) => area(b) - area(a),
        );
        const biggestRegion = orderedByAreaDesc[0];
        const withName = {
          ...biggestRegion,
          properties: {
            ...region.properties,
          },
        };
        return withName;
      },
    );
    const originalRegionsWithoutSelfIntersected: RegionDto[] = fetchedAnalysis.regions.filter(
      (_, index) => !regionsWithSelfIntersections.includes(index),
    );
    const mergedRegions: RegionDto[] = [
      ...originalRegionsWithoutSelfIntersected,
      ...regionsWithoutSelfIntersections,
    ];

    const analysisWihoutSelfIntersectedRegion: Analysis = {
      ...fetchedAnalysis,
      regions: mergedRegions,
    };
    const validationResult: AnalysisCloneValidationResult = validateAnalysis(
      analysisWihoutSelfIntersectedRegion,
      userRole,
      limits,
    );
    const analysisWithValidatedRegions: Analysis = {
      ...analysisWihoutSelfIntersectedRegion,
      regions: validationResult.validatedRegions,
    };
    setUnchangedAnalysis(analysisWithValidatedRegions);

    const invalidDateRanges: DateRangeDto[] = validationResult.invalidDateRanges.map(
      (index) =>
        analysisWithValidatedRegions.info.timeDefinition.dateRanges[index],
    );
    const invalidRegions: RegionDto[] = validationResult.validatedRegions.filter(
      (region) =>
        region.properties.validationResult.status === ValidationStatus.INVALID,
    );
    if (invalidDateRanges.length > 0 || invalidRegions.length > 0) {
      const analysisWithoutInvalidData: Analysis = {
        ...analysisWithValidatedRegions,
        info: {
          bufferRadiusInMeters: fetchedAnalysis.info.bufferRadiusInMeters,
          ...analysisWithValidatedRegions.info,
          timeDefinition: {
            ...analysisWithValidatedRegions.info.timeDefinition,
            dateRanges: analysisWithValidatedRegions.info.timeDefinition.dateRanges.filter(
              (_, index) => !validationResult.invalidDateRanges.includes(index),
            ),
          },
        },
        regions: analysisWithValidatedRegions.regions.filter(
          (_, index) =>
            validationResult.validatedRegions[index].properties.validationResult
              .status === ValidationStatus.OK,
        ),
      };
      setAnalysisWithoutInvalidData(analysisWithoutInvalidData);
      setInvalidDateRanges(invalidDateRanges);
      setInvalidRegions(invalidRegions);
      setDateRangeLimit(
        limits.limits.dateRange === undefined
          ? twoYearsDateRange(isAdmin)
          : {
              start: limits.limits.dateRange.from,
              end: limits.limits.dateRange.to,
              exclusions: [],
            },
      );
      setMinDaysInDateRange(limits.limits.minDaysInDateRange);
      setShowCloneModal(true);
    } else {
      setAnalysisToMenu(analysisWihoutSelfIntersectedRegion);
      setShowNewReportModal(true);
    }
    setCloneLoading(false);
  };

  const validateAnalysis = (
    analysis: Analysis,
    userRole: UserRole,
    limits: LimitsWithUsage,
  ): AnalysisCloneValidationResult => {
    return {
      validatedRegions: analysis.regions.map((region) => {
        return {
          ...region,
          properties: {
            ...region.properties,
            validationResult: validateRegion(
              region,
              analysis.info.type,
              limits.limits.allowedArea,
            ),
          },
        };
      }),

      invalidDateRanges: analysis.info.timeDefinition.dateRanges
        .map((dateRange, orginalIndex) => ({ dateRange, orginalIndex }))
        .filter((hoho) => !isDateRangeValid(hoho.dateRange, limits))
        .map((hoho) => hoho.orginalIndex),
    };
  };

  let button: any;
  if (size === 'small') {
    button = (
      <SimpleTooltip content="Clone" placement="top" usePortal>
        <Button
          variant={props.variant ?? 'flat'}
          shape="circle"
          size="xs"
          data-test="analysis-item-clone"
          onClick={nonPropagated(() => clone(analysisId, userRole))}
          busy={cloneLoading}
          $background={props.isTransparent ? 'transparent' : ''}
        >
          <CloneIcon />
        </Button>
      </SimpleTooltip>
    );
  } else {
    button = (
      <SimpleTooltip content="Clone" placement="top" usePortal>
        <Button
          disabled={cloneLoading}
          variant={props.variant ?? 'flat'}
          onClick={nonPropagated(() => clone(analysisId, userRole))}
          className="AnalysisMap__CloneButton"
          data-test="analysis-item-clone"
          $background={props.isTransparent ? 'transparent' : ''}
          busy={cloneLoading}
          shape="circle"
        >
          <CloneIcon />
        </Button>
      </SimpleTooltip>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      {button}
      {showNewReportModal && (
        <div onClick={(e) => e.stopPropagation()}>
          <NewReportModal
            isOpen={showNewReportModal}
            onClose={() => setShowNewReportModal(false)}
            action="clone"
          />
        </div>
      )}
      {showCloneModal && (
        <AnalysisCloneModal
          analysisName={unChangedAnalysis.info.name}
          invalidDateRanges={invalidDateRanges}
          invalidRegions={invalidRegions}
          onUnchangedClone={nonPropagated(() => {
            setAnalysisToMenu(unChangedAnalysis);
            push({ pathname: '/new/dates' });
          })}
          onCloneWithoutInvalidData={nonPropagated(() => {
            setAnalysisToMenu(analysisWithoutInvalidData);
            push({ pathname: '/new/dates' });
          })}
          dateRangeLimit={dateRangeLimit}
          minDaysInDateRange={minDaysInDateRange}
          onClose={nonPropagated(() => {
            setShowCloneModal(false);
          })}
        />
      )}
    </div>
  );
}

export default AnalysisCloneButton;
