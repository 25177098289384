import { EditSelectedLinkPageTemplate } from './EditSelectedLinkEditTemplate';
import { EmptyPlaceholder } from 'components/UI/EmptyPlaceholder';
import { ActionContainer } from './SelectedLink.style';
import { Button, tombac } from 'tombac';
import { AddPolygonIcon, AddRouteIcon } from 'tombac-icons';
import { SelectorType } from './EditSelectedLink';
import { useValidation } from 'hooks/useValidation';
import { useMenu } from 'reducers/menuReducer';
import { selectedLinkNotEmptyGeometryRequirements } from '../Requirements/requirements';
import LimitsApi from 'api/LimitsApi';
import styled from 'styled-components';

export const NewBadge = styled.div`
  height: ${tombac.space(2.5)};
  width: ${tombac.space(5.5)};
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${tombac.space(0.25, 0.5)};
  border-radius: 4px;
  background: #00aaff;
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  margin-left: -${tombac.space(4)};
  top: -${tombac.space(2.5)};
  line-height: 16px;
  z-index: 5000;
`;

interface Props {
  setSelectorType: (selectorType: SelectorType) => void;
}

export const SelectedLinkGeometrySelectorPage = ({
  setSelectorType,
}: Props) => {
  const [menu] = useMenu();
  const validation = useValidation(
    [...selectedLinkNotEmptyGeometryRequirements],
    { menu },
    () => {},
  );

  const AsideSection = () => (
    <EmptyPlaceholder
      type="regions2"
      subtitle={[
        'Select link or add region to finish the step.',
        'Local roads of minor importance (FRC 7) cannot be selected due to privacy reasons.',
      ]}
      placeholderStyle={{ width: '300px' }}
    />
  );

  const GlMapSection = () => (
    <ActionContainer>
      <span>
        <Button
          variant="tertiary"
          onClick={() => setSelectorType(SelectorType.REGION)}
        >
          <AddPolygonIcon style={{ marginRight: '5px' }} />
          Draw polygon
        </Button>
        <NewBadge>NEW</NewBadge>
      </span>

      <Button
        variant="tertiary"
        onClick={() => setSelectorType(SelectorType.LINK)}
      >
        <AddRouteIcon style={{ marginRight: '5px' }} />
        Add segment
      </Button>
    </ActionContainer>
  );

  return (
    <EditSelectedLinkPageTemplate
      validation={validation}
      asideSection={<AsideSection />}
      glMapSection={<GlMapSection />}
      links={menu.links}
    />
  );
};
