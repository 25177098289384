import { Feature, Geometry } from '@turf/helpers';
import { ValidationResult } from 'components/AnalysisNew/RegionSelection/validation';

export interface RegionDtoProps {
  name?: string;
  id?: string;
  i?: number;
  validationResult?: ValidationResult;
  bidirectional?: boolean;
  edgeIds?: string[];
}

export type RegionDto = Feature<Geometry, RegionDtoProps>;

export interface SegmentProperties {
  id: string;
  inbound: string[];
  outbound: string[];
  length: number;
  mergedFrom: string[];
}

export type SegmentFeature = GeoJSON.Feature<
  GeoJSON.LineString,
  SegmentProperties
>;

export type FRC = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const FRCLabels: { [key in FRC]: string } = {
  [0]: 'Motorways; Freeways; Major Roads',
  [1]: 'Major Roads less important than Motorways',
  [2]: 'Other Major Roads',
  [3]: 'Secondary Roads',
  [4]: 'Local Connecting Roads',
  [5]: 'Local Roads of High Importance',
  [6]: 'Local Roads',
  [7]: 'Local Roads of Minor Importance',
};

/**
 * Format: [id, geo, inbound, outbound, mergedFrom, frc]
 */
export type SegmentResponseItem = [
  string,
  [number, number][],
  string[],
  string[],
  string[],
  FRC,
];
