import Title from 'components/Layout/Title';
import GlMap from 'components/Map/GlMap';
import {
  Aside,
  AsideSection,
  AsideSectionActions,
  AsideTitle,
  FormContent,
  Spacer,
  ValidationMessagesSection,
} from 'components/UI/FormUI';
import { useValidation } from 'hooks/useValidation';
import { Link } from 'react-router-dom';
import { Button } from 'tombac';
import { SearchAndZoom } from '../RegionSelection/SearchAndZoom';
import './EditSelectedLink.css';
import { ZoomInButton } from 'components/UI/UI';
import { centerOnRegions } from 'components/Map/mapUtils';
import { SelectedLinkEdge } from 'reducers/menuReducer';
import { useRef } from 'react';

interface Props {
  validation: ReturnType<typeof useValidation>;
  asideSection: JSX.Element;
  glMapSection: JSX.Element;
  links: SelectedLinkEdge[];
}

export const EditSelectedLinkPageTemplate = ({
  validation,
  asideSection,
  glMapSection,
  links,
}: Props) => {
  const mapRef = useRef<mapboxgl.Map | undefined>(undefined);

  return (
    <>
      <Title>Create link</Title>
      <Aside>
        <AsideTitle index={3} title="Link" />
        <AsideSection grow gray>
          {asideSection}
        </AsideSection>
        <ValidationMessagesSection>
          {validation.messages}
        </ValidationMessagesSection>
        <AsideSectionActions>
          <Spacer />
          <Link to="/new/times">
            <Button variant="flat">Back</Button>
          </Link>
          <Spacer width={2} />
          <Link to={validation.isValid ? '/new/summary' : '#'}>
            <Button
              variant="primary"
              onClick={() => validation.canSubmit()}
              data-test="link-next"
            >
              Next
            </Button>
          </Link>
        </AsideSectionActions>
      </Aside>
      <FormContent variant="default" style={{ display: 'flex' }}>
        <GlMap
          mapStyle="basic_main"
          controlLocation="top-right"
          mapboxRef={(c: mapboxgl.Map) => {
            if (mapRef) {
              mapRef.current = c;
            }
          }}
        >
          <ZoomInButton
            onClick={() => centerOnRegions(mapRef.current, links)}
            position="top-right"
            type="link"
          />
          <SearchAndZoom />
          {glMapSection}
        </GlMap>
      </FormContent>
    </>
  );
};
