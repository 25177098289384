const ANY_ZERO_OR_MORE_CHARACTER = '*';
const ANY_ONE_OR_MORE_CHARACTER = '+';
const EXTERNAL_REGION_CHARACTER = 'E';
const SEPARATOR_CHARACTER = ',';

export const validateQuery = (
  query: string | undefined,
  regionsNumber: number,
): string | undefined => {
  if (query) {
    return undefined;
  }
  const elements = query.split(SEPARATOR_CHARACTER);

  if (elements.length === 0) {
    return 'Query is empty';
  }
  for (const [index, element] of elements.entries()) {
    if (element === ANY_ZERO_OR_MORE_CHARACTER) {
      continue;
    }
    if (element === ANY_ONE_OR_MORE_CHARACTER) {
      continue;
    }
    if (element === EXTERNAL_REGION_CHARACTER) {
      continue;
    }
    const number = Number(element);
    if (
      element.startsWith(' ') ||
      element.endsWith(' ') ||
      element === null ||
      element.trim() === '' ||
      isNaN(number)
    ) {
      return `Invalid query element: '${element}' at position: ${index + 1}`;
    }
    if (number < 0 || number >= regionsNumber) {
      return `Invalid region index: '${element}' at position: ${index + 1}`;
    }
  }

  if (!isExternalOnlyFirstOrLast(query)) {
    return 'External region can be only at beginning or end of the query';
  }

  if (!isEachNextElementDifferentThanPrevious(elements)) {
    return 'Next query elements cannot be the same';
  }

  return undefined;
};

const isExternalOnlyFirstOrLast = (query: string): boolean => {
  const trimmedQuery = query.substring(1, query.length - 1);
  return !trimmedQuery.includes(EXTERNAL_REGION_CHARACTER);
};

const isEachNextElementDifferentThanPrevious = (
  queryElements: string[],
): boolean =>
  !queryElements.some((el, i) => i > 0 && el === queryElements[i - 1]);
