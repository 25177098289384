import emptyDatesImage from '../../svg/empty-dates.svg';
import emptyRegionImage from '../../svg/empty-area.svg';
import emptyRegionImage2 from '../../svg/empty-states-no-locations.svg';
import emptyTimesImage from '../../svg/empty-times.svg';
import emptyImage from '../../svg/empty.svg';
import emptyRoute from './route.svg';
import emptyLinkRegion from '../../svg/empty-selected-link-region.svg';
import styled from 'styled-components';
import { tombac } from 'tombac';
import React from 'react';

export type PlaceholderType =
  | 'dates'
  | 'times'
  | 'regions'
  | 'regions2'
  | 'empty'
  | 'route'
  | 'linkRegion';

const placeholderImage = (type: PlaceholderType) =>
  ({
    dates: emptyDatesImage,
    times: emptyTimesImage,
    regions: emptyRegionImage,
    regions2: emptyRegionImage2,
    empty: emptyImage,
    route: emptyRoute,
    linkRegion: emptyLinkRegion,
  }[type]);

interface EmptyOverviewProps {
  type: PlaceholderType;
  subtitle?: string | string[];
  onlyImage?: boolean;
  placeholderStyle?: React.CSSProperties;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 28px;
`;

const Title = styled.div`
  margin-top: 24px;
  font-family: ${tombac.altFontFamily};
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;

const Subtitle = styled.div`
  margin-top: 8px;
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: normal;
  color: #7a7e80;
  text-align: center;
`;

export const EmptyPlaceholder: React.FC<EmptyOverviewProps> = (props) => {
  const { type, onlyImage } = props;
  const image = placeholderImage(type);
  const subtitleItems = React.useMemo((): string[] => {
    if (Array.isArray(props.subtitle)) {
      return props.subtitle;
    }
    return props.subtitle
      ? [props.subtitle]
      : [`Add ${type} to finish the step`];
  }, [props.subtitle, type]);

  return (
    <Content>
      <img alt="Subtitle" src={image} style={{ height: '170px' }} />
      {!onlyImage && (
        <>
          <Title>Nothing here yet</Title>
          {subtitleItems.map((subtitle) => (
            <Subtitle key={subtitle} style={props.placeholderStyle}>
              {subtitle}
            </Subtitle>
          ))}
        </>
      )}
    </Content>
  );
};
