import { useCallback, useEffect, useState } from 'react';
import { Feature, LineString } from '@turf/helpers';
import { SegmentsApi } from 'api/SegmentsApi';
import useMap from 'hooks/useMap';
import { useMapEvent } from 'hooks/useMapEvent';
import { FRC, SegmentFeature } from 'model/RegionDto';

export const useSegments = (
  mapVersion: string | undefined,
  mapType: string,
  frcs?: FRC[],
): Feature<LineString>[] => {
  const map = useMap();
  const [segments, setSegments] = useState<SegmentFeature[]>([]);

  const updateSegments = useCallback(
    async (mapVersion: string | undefined, mapType: string) => {
      if (!mapVersion) {
        return [];
      }
      let current = true;
      if (map.getZoom() > 13) {
        const segments = await SegmentsApi.getSegments(
          map.getBounds(),
          mapVersion,
          mapType,
          frcs,
        );
        if (!current) return;
        setSegments(segments);
      }
      () => {
        current = false;
      };
    },
    [map],
  );

  useMapEvent('moveend', () => {
    updateSegments(mapVersion, mapType);
  });
  useEffect(() => {
    updateSegments(mapVersion, mapType);
  }, [mapVersion, mapType]);

  return segments;
};
