import LimitsApi from 'api/LimitsApi';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import GlMap from 'components/Map/GlMap';
import DisplayRegions from 'components/Map/Layers/DisplayRegions';
import { CenterOnRegions } from 'components/Map/mapUtils';
import { isSelectedLinkRegionSelected } from 'components/SelectedLinkPage/utils';
import { Kilometers } from 'components/UI/Kilometers/Kilometers';
import Spacer from 'components/UI/Stack/Spacer';
import { HStack, VStack } from 'components/UI/Stack/Stack';
import { AnalysisType } from 'model/AnalysisDto';
import { RegionDto } from 'model/RegionDto';
import { useEffect, useRef, useState } from 'react';
import X from 'react-feather/dist/icons/x';
import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { Button, InlineText, Modal, tombac } from 'tombac';
import { HelpIcon, SpinnerIcon, WarningIcon } from 'tombac-icons';
import RoadSegments from '../RegionSelection/RoadSegments';
import { SelectedLinkFeature } from '../SelectedLink/SelectedLinkFeature';
import { RoadCoverageDescription } from './RoadCoverageDescription';
import { isRegionAnalysis } from 'logic/analysis/analysisUtils';

const Container = styled.div`
  position: absolute;
  margin: 10px;
  bottom: 0;
  left: 470px;
  background: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: ${tombac.space(2)};
  width: 370px;
  font-family: ${tombac.fontFamily};
`;

const Line = styled.div`
  height: 1px;
  background-color: #e5e5e5;
  margin: 12px 0;
  flex-shrink: 0;
  width: 100%;
`;

function RoadCoverage({
  coverage: { coverage, error, loading },
  left,
  regions: maybeRegions,
}: {
  coverage?: any;
  /**
   * Margin left in px
   */
  left?: string;
  regions?: RegionDto[];
}) {
  const [menu] = useMenu();
  const [modal, setModal] = useState(false);
  const limits = LimitsApi.use();

  const hasLimit = Number.isInteger(limits.limits.totalRoadNetwork);

  const remaining = hasLimit
    ? Math.max(
        0,
        limits.limits.totalRoadNetwork -
          limits.usage.totalRoadNetwork -
          (coverage ?? 0),
      )
    : undefined;

  const regions =
    maybeRegions ??
    (isRegionAnalysis(menu.type) || isSelectedLinkRegionSelected(menu)
      ? menu.regions
      : menu.links);

  return (
    <>
      {modal && (
        <RoadCoverageModal
          regions={regions}
          coverage={coverage}
          type={menu.type}
          onClose={() => setModal(false)}
        />
      )}
      <Container style={{ left }}>
        <VStack spacing={'none'}>
          <HStack>
            <InlineText
              altFont
              fontSize={12}
              fontWeight={700}
              $display="grid"
              $gridTemplateColumns="1fr 24px 24px"
              $alignItems="center"
            >
              Pricing coverage:{' '}
              {!error &&
                (regions.length > 0 && loading ? (
                  <SpinnerIcon spin />
                ) : (
                  <Kilometers squared={false} value={coverage ?? 0} />
                ))}
              <SimpleTooltip content="Pricing coverage is the length of roads that we take into consideration when calculating pricing depending on the regions of your analysis.">
                <HelpIcon style={{ paddingLeft: '8px' }} />
              </SimpleTooltip>
              {error && (
                <SimpleTooltip
                  content={`Regions area exceeded the report limit.
                Edit regions to see pricing coverage.`}
                >
                  <WarningIcon color="#f9b023" style={{ paddingLeft: '8px' }} />
                </SimpleTooltip>
              )}
            </InlineText>
            <Spacer />
            {coverage > 0 && !loading && !error && (
              <Button
                size="s"
                style={{
                  background: 'none',
                  color: '#2a90d5',
                  height: '20px',
                }}
                onClick={() => setModal(true)}
              >
                Preview
              </Button>
            )}
          </HStack>
          {hasLimit && (
            <>
              <Line />
              Remaining global pricing coverage limit:{' '}
              <Kilometers squared={false} value={remaining} />
            </>
          )}
        </VStack>
      </Container>
    </>
  );
}

function RoadCoverageModal({
  regions,
  coverage,
  type,
  onClose,
}: {
  regions: RegionDto[];
  coverage: number;
  type: AnalysisType;
  onClose: () => void;
}) {
  const [menu] = useMenu();
  const map = useRef<mapboxgl.Map | undefined>();
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <Modal isOpen onRequestClose={onClose}>
      <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          padding: '14px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <X style={{ width: '20px', height: '20px' }} />
      </div>
      <div
        style={{
          width: '70vw',
          height: '850px',
          position: 'relative',
          display: 'flex',
        }}
      >
        <RoadCoverageDescription coverage={coverage} />

        <GlMap
          mapboxRef={(m) => (map.current = m)}
          mapStyle="basic_main"
          independent
        >
          <CenterOnRegions regions={regions} />
          {type === AnalysisType.FlowMatrix ||
          isSelectedLinkRegionSelected(menu) ? (
            <DisplayRegions
              dtoRegions={regions}
              layerId="road-coverage-regions"
            />
          ) : (
            <SelectedLinkFeature links={regions} />
          )}
          <RoadSegments regions={regions} />
        </GlMap>
      </div>
    </Modal>
  );
}

export default RoadCoverage;
