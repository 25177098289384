import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { Box, Caption, Checkbox, Label, Text, tombac, Tooltip } from 'tombac';
import { HelpIcon } from 'tombac-icons';
import { Progress } from './Progress';
import { SliderInput } from 'components/SelectedLinkPage/SliderInput';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { FRC, FRCLabels } from 'model/RegionDto';
import { SelectedLinkSettingsContainer } from './SelectedLink.style';
import './EditSelectedLink.css';

export const LINK_MAX_LENGTH_IN_METERS = 2_000;
const MIN_LINK_RADIUS_IN_KILOMETERS = 5;
const MAX_LINK_RADIUS_IN_KILOMETERS = 50;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tombac.space(2)};
`;

export const SettingsLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  showRadius: boolean;
  setShowRadius: (showRadius: boolean) => void;
  selectedLength: number;
  selectedFrcs: FRC[];
  setBufferRadiusInKilometers: (bufferRadiusInKilometers: number) => void;
  bufferRadiusInKilometers: number;
}

export const LinkSettings = ({
  showRadius,
  setShowRadius,
  selectedLength,
  selectedFrcs,
  setBufferRadiusInKilometers,
  bufferRadiusInKilometers,
}: Props) => {
  const [, setMenu] = useMenu();

  return (
    <SelectedLinkSettingsContainer>
      <Progress
        label="Segments length"
        value={Math.floor(selectedLength)}
        max={LINK_MAX_LENGTH_IN_METERS}
        unit="meters"
      />
      <SettingsContainer>
        <SettingsLabel>
          <Label
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
            }}
          >
            {' '}
            Radius{' '}
            <SimpleTooltip content="The radius of trips from the link that can be analyzed.">
              <HelpIcon />
            </SimpleTooltip>
          </Label>
          <Checkbox
            label={
              <Caption $color={tombac.color('neutral', 700)}>
                Show radius
              </Caption>
            }
            size="xs"
            variant="toggle"
            checked={showRadius}
            labelPlacement="before"
            onChange={() => setShowRadius(!showRadius)}
          />
        </SettingsLabel>{' '}
        <Box>
          <SliderInput
            min={MIN_LINK_RADIUS_IN_KILOMETERS}
            max={MAX_LINK_RADIUS_IN_KILOMETERS}
            value={bufferRadiusInKilometers}
            unit="km"
            onChange={(v) => setBufferRadiusInKilometers(Math.floor(v))}
            onAfterChange={(v) => {
              setMenu({
                bufferRadiusInKilometers: Math.floor(v),
              });
            }}
          />
        </Box>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsLabel>
          <Label
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
            }}
          >
            {' '}
            Selected road classes (FRC){' '}
            <Tooltip
              placement="top"
              variant="inverted"
              content={
                <>
                  <Text fontSize={14} $color={tombac.color.white}>
                    FRC - Functional Road Classes are designed to categorize
                    roads according to their functional importance and capacity.
                  </Text>
                  <a
                    href="https://developer.tomtom.com/traffic-stats/documentation/product-information/faq#what-are-functional-road-classes-frc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text fontSize={14} fontWeight={700} variant="interactive">
                      Learn more
                    </Text>
                  </a>
                </>
              }
            >
              {({ ref, toggle }) => (
                <div ref={ref}>
                  <HelpIcon onMouseEnter={toggle} />
                </div>
              )}
            </Tooltip>
          </Label>
        </SettingsLabel>
        {selectedFrcs.length > 0 ? (
          <Text>
            {selectedFrcs.map((frc) => `${frc} - ${FRCLabels[frc]}`).join('; ')}
          </Text>
        ) : null}
      </SettingsContainer>
    </SelectedLinkSettingsContainer>
  );
};
