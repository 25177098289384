import { Geometry } from '@turf/helpers';
import { toCorrectFormat } from 'components/AnalysisNew/Date/utils';
import { DataType } from 'fontModels/DataSource';
import {
  LimitUsages,
  LimitValues,
  useMeProductAccessQuery,
} from 'legoland-sdk/dist/experimental';
import { clamp } from 'logic/math';
import { twoYearsDateRange } from 'logic/time/timeValidation';
import { useMenu } from 'reducers/menuReducer';
import { useIsAdmin } from 'user';

interface AppLimitValues extends LimitValues {
  // Default limits
  /** Amount of regions per analysis. Default: 200. Required */
  maxRegionCount: number;
  /** Regions area per analysis. Default: 3000. Required */
  maxAreaSize: number;
  /** Amount of date ranges per analysis. Default: 4. Required */
  maxDateRanges: number;
  /** Amount of time ranges per analysis. Default: 4. Required */
  maxTimeSets: number;
  /** Amount of days in single date range. Default: 28. Required */
  minDaysInDateRange: number;

  /** Whether user can enable limit data set with extended date range */
  limitedDataSourcesEnabled?: boolean;
  /** Whether user can exclude organizations with mobile devices */
  excludeMobileSourcesEnabled?: boolean;
  /** Whether user can create flow links analysis */
  flowLinksEnabled?: boolean;

  /** Regions area per user */
  totalAreaInSquareKm?: number;
  /** Amount of allowed reports */
  agreementJobs?: number;
  /** Total network length limit */
  totalRoadNetwork?: number;
  /** Geojson region definition of allowed area, "fog of war" */
  allowedArea?: Geometry;
  /** Allowed date range. Usually a moving window for evaluation accounts */
  dateRange?: {
    from?: Date;
    to?: Date;
  };
}

interface AppLimitUsages extends LimitUsages {
  agreementJobs?: number;
  totalAreaInSquareKm?: number;
  totalRoadNetwork?: number;
}

export interface LimitsWithUsage {
  limits: AppLimitValues;
  usage: AppLimitUsages;
}

const defaultAccess: LimitsWithUsage = {
  limits: {
    maxRegionCount: 200,
    maxAreaSize: 3000,
    maxDateRanges: 4,
    maxTimeSets: 24,
    minDaysInDateRange: 1,
  },
  usage: {
    totalAreaInSquareKm: 0,
    agreementJobs: 0,
    totalRoadNetwork: 0,
  },
};

const extendedMinDate = new Date('2018-01-01');

const getDateRangeLimit = (dataSource: DataType, isAdmin: boolean) => {
  const twoYears = twoYearsDateRange(isAdmin);
  switch (dataSource) {
    case DataType.All: {
      return { from: twoYears.start, to: twoYears.end };
    }
    case DataType.Limited: {
      return { from: extendedMinDate, to: twoYears.end };
    }
  }
};

const useLimits = (): LimitsWithUsage | undefined => {
  const [menu] = useMenu();
  const isAdmin = useIsAdmin();
  const { data, error, isLoading } = useMeProductAccessQuery();

  if (isLoading) return;
  if (error) console.error(error);

  // Deep clone
  const access = ((data && JSON.parse(JSON.stringify(data.access))) ?? {
    limits: undefined,
    usage: {},
  }) as { limits?: Record<string, unknown>; usage: Record<string, unknown> };

  const defaultDateRangeLimit = getDateRangeLimit(menu?.dataType, isAdmin);
  // Use defaults if move is not responding
  if (access.limits === undefined) {
    access.limits = defaultAccess.limits;
    access.limits.dateRange = defaultDateRangeLimit;
  }

  // Convert string to date
  if (access.limits.dateRange) {
    const range = access.limits.dateRange as { from: string; to: string };

    let to = range.to
      ? new Date(toCorrectFormat(range.to))
      : defaultDateRangeLimit.to;
    // Make sure date limit is not in the future
    if (to > defaultDateRangeLimit.to) to = defaultDateRangeLimit.to;

    access.limits.dateRange = {
      from: range.from
        ? new Date(toCorrectFormat(range.from))
        : defaultDateRangeLimit.from,
      to,
    };
  } else {
    access.limits.dateRange = defaultDateRangeLimit;
  }

  return access as LimitsWithUsage;
};

const limitLeft = (limitName: keyof AppLimitUsages) => (
  limits: LimitsWithUsage,
) => {
  const usage = limits.usage[limitName] as number;
  const limit = limits.limits[limitName] as number;

  return clamp(0, Infinity, limit - usage);
};

const reportsLeft = limitLeft('agreementJobs');
const areaLeft = limitLeft('totalAreaInSquareKm');

const LimitsApi = { reportsLeft, areaLeft, use: useLimits };
export default LimitsApi;
