import { VehicleTypes } from 'fontModels/DataSource';
import { useMenu } from 'reducers/menuReducer';
import { ToggleGroup } from 'tombac';

export const VehicleTypesRadio = () => {
  const [menu, setMenu] = useMenu();

  const radioGroupOnChange = (vehicleTypes: VehicleTypes) => {
    setMenu({ vehicleTypes });
  };

  return (
    <ToggleGroup.Root
      value={menu.vehicleTypes}
      $width="360px"
      groupContainerProps={{ $width: '100%' }}
      onChange={(vehicleTypes) =>
        radioGroupOnChange(vehicleTypes as VehicleTypes)
      }
    >
      <ToggleGroup.Item value={VehicleTypes.All}>All</ToggleGroup.Item>
      <ToggleGroup.Item value={VehicleTypes.Passenger}>
        Passenger
      </ToggleGroup.Item>
      <ToggleGroup.Item value={VehicleTypes.Fleet}>Fleet</ToggleGroup.Item>
    </ToggleGroup.Root>
  );
};
